import React, { useState, useCallback } from 'react'
import clsx from 'clsx'
import { AddressAutofill, AddressMinimap, useConfirmAddress } from '@mapbox/search-js-react'


// TO MAKE THE MAP APPEAR YOU MUST
// ADD YOUR ACCESS TOKEN FROM
// https://account.mapbox.com
const ACCESS_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;

const AddressForm = () => {
    // one of 'shipping', 'confirm', 'complete'
    const [activePage, setActivePage] = useState('shipping')
    const [formData, setFormData] = useState()
    const [minimapFeature, setMinimapFeature] = useState()
    const { formRef, showConfirm } = useConfirmAddress({
                                                           accessToken: ACCESS_TOKEN
                                                       });
    const [coordinates, setCoordinates] = useState({latitude: 0, longitude: 0})
    const [latitude, setLatitude] = useState(0)
    const [longitude, setLongitude] = useState(0)

    const handleAutofillRetrieve = (response) => {
        setMinimapFeature(response.features[0])
        console.log(response.features[0].geometry.coordinates[1])
        setLatitude(response.features[0].geometry.coordinates[1])
        setLongitude(response.features[0].geometry.coordinates[0])
    }

    const handleFormSubmit = useCallback(async (e) => {
        e.preventDefault()
        const result = await showConfirm()

        // if no change is suggested, the address is confirmed. continue to the confirmation page
        if (result.type === 'nochange') {
            setFormData(new FormData(e.target))
            setActivePage('confirm')
        }
    }, [showConfirm]);

    const handleChangeAddress = () => {
        setActivePage('shipping')
    }

    const handleOrderSubmit = () => {
        setActivePage('complete')
    }

    const handleTryAgain = () => {
        formRef.current.reset()
        setMinimapFeature()
        setActivePage('shipping')
    }

    let displayAddress

    if (formData) {
        displayAddress = (
                <>
                    {formData.get('first-name')} {formData.get('last-name')}<br />
                    {formData.get('address-line1 address-search')}<br />
                    {formData.get('address-line2') && (<>{formData.get('address-line2')} < br /></>)}
                    {formData.get('address-level2')} {formData.get('address-level1')} {formData.get('postal-code')}
                </>
        )
    }

    return (
            <div className="p-2" style={{ minHeight: 550 }}>
                <div className="wmax600 mx-auto">
                    {/* shipping address page */}
                    <div className={clsx("address-page", {
                        'none': activePage !== 'shipping'
                    })}>
                        <h4 className="txt-l txt-bold mb6">Shipping Address</h4>

                        {/* address form with `autocomplete` attributes on each address input */}
                        <form className="" id="address-form" ref={formRef} onSubmit={handleFormSubmit}>
                            {/* first and last name */}
                            <div className=''>
                                <div className='mt-2'>
                                    <label className="txt-s txt-bold color-gray mb3">
                                        Name
                                        <input className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6" name="first-name" required />
                                    </label>
                                </div>

                            </div>

                            {/* address-line1 with search icon */}
                            <AddressAutofill
                                    accessToken={ACCESS_TOKEN}
                                    onRetrieve={handleAutofillRetrieve}
                            >
                                <label className="txt-s txt-bold color-gray mb3">
                                    Address
                                    <div className="relative color-gray">
                                        <input data-lpignore="true" className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6" autoComplete="address-line1" name="address-line1" required />
                                        <svg className="absolute" viewBox="0 0 18 18" xmlSpace="preserve" width="20" height="20"
                                             fill="currentColor" style={{ top: 8, right: 8 }}>
                                            <path
                                                    d="M7.4 2.5c-2.7 0-4.9 2.2-4.9 4.9s2.2 4.9 4.9 4.9c1 0 1.8-.2 2.5-.8l3.7 3.7c.2.2.4.3.8.3.7 0 1.1-.4 1.1-1.1 0-.3-.1-.5-.3-.8L11.4 10c.4-.8.8-1.6.8-2.5.1-2.8-2.1-5-4.8-5zm0 1.6c1.8 0 3.2 1.4 3.2 3.2s-1.4 3.2-3.2 3.2-3.3-1.3-3.3-3.1 1.4-3.3 3.3-3.3z">
                                            </path>
                                        </svg>
                                    </div>
                                </label>
                            </AddressAutofill>

                            {/* address-line2 */}
                            <label className="txt-s txt-bold color-gray mb3">
                                Apartment, suite, etc. (optional)
                                <input className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6" autoComplete="address-line2" name="address-line2" />
                            </label>

                            {/* address-level2, address-level1, postal-code */}
                            <div className='mt-2'>
                                <div className=''>
                                    <label className="txt-s txt-bold color-gray mb3">
                                        City
                                        <input className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6" autoComplete="address-level2" name="address-level2" required />
                                    </label>
                                </div>
                                <div className='col w-1/3'>
                                    <label className="txt-s txt-bold color-gray mb3">
                                        State / Region
                                        <input className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6" autoComplete="address-level1" name="address-level1" required />
                                    </label>
                                </div>
                                <div className='col w-1/3'>
                                    <label className="txt-s txt-bold color-gray mb3">
                                        ZIP / Postcode
                                        <input className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6" autoComplete="postal-code" name="postal-code" required />
                                    </label>
                                </div>
                                <div className='col w-1/3'>
                                    <label className="txt-s txt-bold color-gray mb3">
                                        Country
                                        <input type={'hidden'} className="block w-full rounded-md bg-white px-3 py-1.5 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-indigo-600 sm:text-sm/6" autoComplete="country" name="country" required />
                                    </label>
                                </div>
                                <div className={'mt-2'}>
                                    <input type={'hidden'} defaultValue={longitude} name={'longitude'}/>
                                    <input type={'hidden'} defaultValue={latitude} name={'latitude'}/>

                                    {longitude} {latitude}
                                </div>

                            </div>

                            {/* minimap for visual confirmation */}
                            <div id="minimap-container" className={clsx("h180 wfull relative mt18 mb60", {
                                none: !minimapFeature
                            })}>
                                <AddressMinimap
                                        feature={minimapFeature}
                                        show={!!minimapFeature}
                                        satelliteToggle
                                        canAdjustMarker
                                        footer
                                        accessToken={ACCESS_TOKEN}
                                />
                            </div>

                            {/* continue button */}
                            <div className="mb12 submit-btns align-r">
                                <button type="submit" className="btn round">
                                    Continue
                                </button>
                            </div>
                        </form>
                        {/* end address form */}
                    </div>


                </div>
            </div>
    )
}

export default AddressForm
