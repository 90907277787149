import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, Navigate, RouterProvider, useLocation} from "react-router-dom";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {HelmetProvider} from "react-helmet-async";
import {MetaTags} from "./components/common/utilities";
import ErrorPage from "./pages/ErrorPage";
import ViewEvent from "./pages/events/viewEvent";
import axios from "axios";
import ViewArtist from "./pages/attractions/viewArtist";
import ViewVenue from "./pages/venues/viewVenue";
import Mediaiceland from "./pages/documentation/mediaiceland";
import Admarketiceland from "./pages/documentation/admarketiceland";
import Whatarewedoingforthemedia from "./pages/documentation/whatarewedoingforthemedia";
import AddEventsIceland from "./pages/documentation/addeventsiceland";
import FindEvents from "./pages/events/findEvents";
import Toplist from "./pages/statistics/toplist";
import LoginPage from "./pages/authentication/loginPage";
import CreatePage from "./pages/authentication/createUser";
import VerifyEmailPage from "./pages/authentication/verifyEmail";
import SendResetLinkPage from "./pages/authentication/sendResetLinkj";
import NewPasswordPage from "./pages/authentication/resetPassword";
import ViewInvite from "./pages/authentication/viewInvite";
import UserDashboard from "./pages/dashboard/userdashboard";
import Dashboard from "./pages/dashboard/dashboard";
import NLPDashboard from "./pages/nlp/nlpdash";

let pagetitle = 'Mobilitus.is';

function RequireAuth({children}: { children: JSX.Element })
{
    let auth = false;
    if (localStorage.getItem('ticket') != null && localStorage.getItem('ticket') != '')
    {
        auth = true;
    }
    let location = useLocation();

    if (!auth)
    {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/loginuser" state={{from: location}} replace/>;
    }

    return children;
}

function NotAuth({children}: { children: JSX.Element })
{
    let auth = false;
    if (localStorage.getItem('ticket') != null && localStorage.getItem('ticket') != '')
    {
        auth = true;
    }
    let location = useLocation();

    if (auth)
    {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/" state={{from: location}} replace/>;
    }

    return children;
}

const router = createBrowserRouter([{
    path: "/", element: <App/>, errorElement: <ErrorPage/>,
}, {
    path: "/dashboard",
    element: <RequireAuth><Dashboard/></RequireAuth>,
    errorElement: <ErrorPage/>,
    loader: async ({params}) => {
        //console.log('ticket', localStorage.getItem('ticket'));
        if (localStorage.getItem('ticket') == null || localStorage.getItem('ticket') == '')
        {
            localStorage.removeItem('ticket');
            window.location.href = '/loginuser';
        }
        return axios.post(`${process.env.REACT_APP_API_URL}/p/user/appdashboard.do`, {
            json: 'true', ticket: localStorage.getItem('ticket'),

        }, {
                              headers: {
                                  'Content-Type': 'application/x-www-form-urlencoded',
                              }
                          }).then(function (response) {
            //console.log('response status: ' + response.status);
            document.title = "Backstage Dashboard";
            /*try
            {
                JSON.parse(response.data);
            }
            catch (e)
            {
                localStorage.removeItem('ticket');
                window.location.href = '/loginuser';
            }*/

            // handle success
            //console.log(response);
            let location = response.data;
            return location;


        }).catch(function (error) {
            console.log('error', error);
            localStorage.removeItem('ticket');
            window.location.href = '/loginuser';
        });
    },
}, {
    path: "/manage/user",
    element: <RequireAuth><UserDashboard/></RequireAuth>,
    errorElement: <ErrorPage/>,
    loader: async ({params}) => {
        //console.log('ticket', localStorage.getItem('ticket'));
        if (localStorage.getItem('ticket') == null || localStorage.getItem('ticket') == '')
        {
            localStorage.removeItem('ticket');
            window.location.href = '/loginuser';
        }
        return axios.post(`${process.env.REACT_APP_API_URL}/p/user/appdashboard.do`, {
            json: 'true', ticket: localStorage.getItem('ticket'),

        }, {
                              headers: {
                                  'Content-Type': 'application/x-www-form-urlencoded',
                              }
                          }).then(function (response) {
            //console.log('response status: ' + response.status);
            document.title = "Backstage Dashboard";
            /*try
            {
                JSON.parse(response.data);
            }
            catch (e)
            {
                localStorage.removeItem('ticket');
                window.location.href = '/loginuser';
            }*/

            // handle success
            //console.log(response);
            let location = response.data;
            return location;


        }).catch(function (error) {
            console.log('error', error);
            localStorage.removeItem('ticket');
            window.location.href = '/loginuser';
        });
    },
}, {
    path: "/invite/:token", element: <ViewInvite/>, errorElement: <ErrorPage/>, loader: async ({params}) => {
        return axios.post(`${process.env.REACT_APP_API_URL}/p/user/viewinvite.do`, {
            token: params.token,
        }, {
                              headers: {
                                  'Content-Type': 'application/x-www-form-urlencoded'
                              }
                          }).then(function (response) {
            // handle success
            //console.log(response);
            let location = response.data;
            return location;

        });
    },
}, {
    path: "/event/:wildcard/:eventID", element: <ViewEvent/>, errorElement: <ErrorPage/>,
}, {
    path: "/event/:eventID", element: <ViewEvent/>, errorElement: <ErrorPage/>,

}, {
    path: "/:wildcard/artist/:artistID",
    element: <ViewArtist/>,
    errorElement: <ErrorPage/>,
    loader: async ({params}) => {

        return axios.get(`${process.env.REACT_APP_CACHE_URL}/p/api/artist.do?`, {
            params: {
                artist: params.artistID, tiny: true,
            }
        }).then(function (response) {
            let location = response.data;
            pagetitle = location.name;
            return location;


        }).catch(function (error) {
            console.log('error', error);

        });
    },
}, {
    path: "/artist/:artistID", element: <ViewArtist/>, errorElement: <ErrorPage/>, loader: async ({params}) => {

        return axios.get(`${process.env.REACT_APP_CACHE_URL}/p/api/artist.do?`, {
            params: {
                artist: params.artistID, tiny: true,
            }
        }).then(function (response) {
            let location = response.data;
            pagetitle = location.name;
            return location;


        }).catch(function (error) {
            console.log('error', error);
        });
    },
}, {
    path: "/venue/:venueID", element: <ViewVenue/>, errorElement: <ErrorPage/>, loader: async ({params}) => {

        return axios.get(`${process.env.REACT_APP_CACHE_URL}/p/api/venue.do?`, {
            params: {
                venue: params.venueID, tiny: true,
            }
        }).then(function (response) {
            let location = response.data;
            pagetitle = location.name;
            return location;


        }).catch(function (error) {
            console.log('error', error);
        });
    },
}, {
    path: "/nlpdash",
    element: <RequireAuth><NLPDashboard/></RequireAuth>,
    errorElement: <ErrorPage/>,
    loader: async ({params}) => {
        //console.log('ticket', localStorage.getItem('ticket'));
        if (localStorage.getItem('ticket') == null || localStorage.getItem('ticket') == '')
        {
            localStorage.removeItem('ticket');
            window.location.href = '/loginuser';
        }
        return axios.post(`${process.env.REACT_APP_API_URL}/p/user/appdashboard.do`, {
            json: 'true',
            ticket: localStorage.getItem('ticket'),

        },{
                              headers: {
                                  'Content-Type': 'application/x-www-form-urlencoded',
                              }
                          }).then(function (response) {
            //console.log('response status: ' + response.status);
            /*try
            {
                JSON.parse(response.data);
            }
            catch (e)
            {
                localStorage.removeItem('ticket');
                window.location.href = '/loginuser';
            }*/

            // handle success
            //console.log(response);
            let location = response.data;
            return location;


        }).catch(function (error) {
            console.log('error', error);
            localStorage.removeItem('ticket');
            window.location.href = '/loginuser';
        });
    },
},{
    path: "/findevent", element: <FindEvents/>, errorElement: <ErrorPage/>, loader: async ({params}) => {
        pagetitle = 'Find event';
        return {};
    },
}, {
    path: "/loginuser", element: <NotAuth><LoginPage/></NotAuth>, errorElement: <ErrorPage/>,

}, {
    path: "/login", element: <NotAuth><LoginPage/></NotAuth>, errorElement: <ErrorPage/>,

}, {
    path: "/createuser", element: <NotAuth><CreatePage/></NotAuth>, errorElement: <ErrorPage/>,

}, {
    path: "/verifyemail/:token", element: <VerifyEmailPage/>, errorElement: <ErrorPage/>,

}, {
    path: "/sendresetlink", element: <SendResetLinkPage/>, errorElement: <ErrorPage/>,

}, {
    path: "/resetpassword/:token", element: <NewPasswordPage/>, errorElement: <ErrorPage/>

}, {
    path: "/logout", element: <NotAuth><LoginPage/></NotAuth>, errorElement: <ErrorPage/>, loader: async ({params}) => {

        return axios.get(`${process.env.REACT_APP_CACHE_URL}/p/logout.do`, {
            params: {
                ticket: localStorage.getItem('ticket'),
            }
        }).then(function (response) {
            localStorage.removeItem('ticket');
            localStorage.clear();
            window.location.href = '/';
        }).catch(function (error) {
            console.log('error', error);
        });

    }},{
    path: "/toplist", element: <Toplist/>, errorElement: <ErrorPage/>,
}, {
    path: "/media", element: <Mediaiceland/>, errorElement: <ErrorPage/>,
}, {
    path: "/admarket", element: <Admarketiceland/>, errorElement: <ErrorPage/>,
}, {
    path: "/forthemedia", element: <Whatarewedoingforthemedia/>, errorElement: <ErrorPage/>,
}, {
    path: "/addevent", element: <AddEventsIceland/>, errorElement: <ErrorPage/>, loader: async ({params}) => {
        pagetitle = 'Add event';
        return {};
    },
}


                                   ]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<HelmetProvider>
    <MetaTags
        title='Mobilitus.is'
        description='Allir vi�bur�ir � �llum svi�um um allt land'
        image='https://media.promogogo.com/gjugg/2021-07-12/b76bd1a87b7d4d01a458f19e02612acd.png'
        name='Team Mobilitus'
    />
    <RouterProvider router={router}/>
</HelmetProvider>);

function sendToAnalytics(): any
{
    let digitalData = {
        "skipCount": false, "page": {
            "pageInfo": {
                "pageID": document.URL,
                "pageName": pagetitle,
                "destinationURL": document.location.href,
                "author": "Toti Stefansson",
                "issueDate": "2021-04-16T13:23:09.000Z",
                "publisher": "mobilitusehf",
                "instance": "promogogo",
                "category": {},
                "attributes": {
                    "posterImage": "https://media.promogogo.com/gjugg/2021-07-12/b76bd1a87b7d4d01a458f19e02612acd.png",
                    "posterImagearticlelist": "https://media.promogogo.com/2021-07-12/715365cdb9e34a6baa2e66880333ce15.png",
                    "posterImageDesc": "https://media.promogogo.com/gjugg/2021-07-12/b76bd1a87b7d4d01a458f19e02612acd.png",
                    "url": "gogopage-pheew",
                    "seoUrl": "gjugg-vidburdir",
                    "type": "LANDINGPAGE",
                    "pageUrl": document.URL,
                    "referrer": document.referrer,

                },
            }
        }
    };
    var pggTrackData = {
        faceUrl: "mobilitusehf", instance: "promogogo", digitalData
    };
    const headers = {
        type: 'application/json',
    };
    const body = new Blob([JSON.stringify(pggTrackData)], headers);
    const url = 'https://count.promogogo.com/v1';

    // Use `navigator.sendBeacon()` if available, falling back to `fetch()`
    fetch(url, {body, method: 'POST', keepalive: true, headers: {"Content-Type": "application/json;charset=UTF-8"}});
    return true;
}

reportWebVitals(sendToAnalytics());
