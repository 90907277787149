import React, {Fragment, useEffect, useState} from 'react'
import {Disclosure, RadioGroup, Tab, Menu, Transition} from '@headlessui/react'
import {
    BellIcon,
    StarIcon,
    FolderOpenIcon,
    PencilSquareIcon,
    CheckIcon, ChevronRightIcon
} from '@heroicons/react/20/solid'
import {HeartIcon, MinusIcon, PlusIcon, XMarkIcon, Bars3Icon} from '@heroicons/react/24/outline'
import axios from "axios";
import {Link, useLocation} from "react-router-dom";
import PropertyNavigation from "../../components/common/navigation.component";



function classNames(...classes: any[])
{
    return classes.filter(Boolean).join(' ')
}

export default function FindPerformer({ venueData }) {
    const [venueList, setVenueList] = useState<any[]>([])
    const location = useLocation();
    const [totalSize, setTotalSize] = useState(0);


    useEffect(() => {

        //getEvents("");
        getVenue("");
        //getPhotos();

    }, []);

    function getVenue(e : any){
        const query = venueData ? venueData.name : '';
        if(query){
            axios.get(`${process.env.REACT_APP_API_URL}/p/ajax/search/findbytype.do?both=false&q=`+query, {
            })
                .then(function (response) {
                    // handle success
                    //console.log(response);
                    let location = response.data;
                    console.log(location);
                    setVenueList(location.collection);
                    setTotalSize(response.data.pagination.totalSize)
                    return location;

                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                    return error;
                })
                .finally(function () {
                    return 'done';
                });



        }
        else return;
    }



    const sendData = () => {
        this.props.sendData('Data from child');
    }

    function venueName(e : any){
        const query = e.currentTarget.value;
        if(query){
            axios.get(`${process.env.REACT_APP_API_URL}/p/ajax/search/searchvenues.do?both=false&q=`+query, {
            })
                .then(function (response) {
                    // handle success
                    //console.log(response);
                    let location = response.data;
                    console.log(location);
                    setVenueList(location.collection);
                    return location;

                })
                .catch(function (error) {
                    // handle error
                    console.log(error);
                    return error;
                })
                .finally(function () {
                    return 'done';
                });



        }
        else return;
    }
    return (

        <div className="min-h-full">
            <div className="bg-white ">

                                <div className="mx-auto mt-6 max-w-2xl leading-8">
                                    <div
                                        className="flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                                        <input
                                            type="text"
                                            name="venue_name"
                                            id="venue_name"
                                            className="block flex-1 border-0 bg-transparent  text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 p-2"
                                            placeholder="Venue"
                                            defaultValue={venueData ? venueData.name : ''}
                                            onChange= {venueName}
                                        />
                                    </div>
                                    <p className="mt-2 text-sm text-gray-500" id="email-description">
                                        {totalSize > 0 && totalSize}  performers matching {venueData.name}:
                                    </p>
                                </div>

                                <div className="mt-2">


                                    <div className="mt-2">
                                        <ul role="list" className="divide-y divide-gray-100">
                                            {venueList.map((venue,index) => (
                                                <li key={venue.artistID} className="relative py-5 hover:bg-gray-50 cursor-pointer">
                                                    <div className="px-4 sm:px-6 lg:px-8" data-performerindex={index} data-performerid={venue.artistID} data-performername={venue.name} data-performerimage={venue.bestImage}>
                                                        <div className="mx-auto flex max-w-4xl justify-between gap-x-6">
                                                            <div className="flex gap-x-4">
                                                                <img className="h-12 w-12 flex-none rounded-full bg-gray-50" src={venue.bestImage} alt="" />
                                                                <div className="min-w-0 flex-auto">
                                                                    <p className="text-sm font-semibold leading-6 text-gray-900">

                                                                            <span className="absolute inset-x-0 -top-px bottom-0" data-button={'editPerformer'} data-performerindex={index} data-performerid={venue.artistID} data-performername={venue.name} data-performerimage={venue.bestImage}/>
                                                                            {venue.name}

                                                                    </p>
                                                                    <p className="mt-1 flex text-xs leading-5 text-gray-500">
                                                                        {venue.majorType}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="flex items-center gap-x-4">
                                                                <div className="hidden sm:flex sm:flex-col sm:items-end">
                                                                    {venue.artistID === venueData['@id'] && <CheckIcon className={'h-5 w-5 text-green-950'}/>}
                                                                    {venue.isActive ? (
                                                                        <p className="mt-1 text-xs leading-5 text-gray-500">
                                                                            Active
                                                                        </p>
                                                                    ) : (
                                                                         <div className="mt-1 flex items-center gap-x-1.5">
                                                                             <div className="flex-none rounded-full bg-emerald-500/20 p-1">
                                                                                 <div className="h-1.5 w-1.5 rounded-full bg-emerald-500" />
                                                                             </div>
                                                                             <p className="text-xs leading-5 text-gray-500">Not active</p>
                                                                         </div>
                                                                     )}
                                                                </div>
                                                                <ChevronRightIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>

                            </div>

                        </div>)
}
